import React from "react"
import Layout from "../components/layout"
import styled from "styled-components"

const Iframer = styled.div`
  margin-top: 150px;
  iframe {
    margin: 0 auto;
    display: block;
    width: 100%;
  }
`

const Calendar = () => {
  const iframe1 = () => {
    return {
      __html: iframe,
    }
  }

  const iframe =
    '<iframe src="https://calendar.google.com/calendar/embed?src=51cl2rriu2bdpegsq477to1cp59p4ntg%40import.calendar.google.com&ctz=America%2FEdmonton" style="border: 0" width="100%" height="800" frameborder="0" scrolling="no"></iframe>'

  return (
    <Layout>
      <Iframer dangerouslySetInnerHTML={iframe1()} />
    </Layout>
  )
}

export default Calendar
